.euiOverlayMask {
    background-color: rgba(255, 255, 255, 0.2)
}

.ElasticUIListPage .ReactTable {
    width: inherit;
    border: 0;
}

.euiTab {
    display: inline;
}

.euiTab:hover {
    background: #f5f5f5;
}

