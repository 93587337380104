@import "AppNavBarCustom";
@import "AppTopNavbar";
@import "CalculationReview";
@import "DayBackground";
@import "DayTimeScale";
@import "ElasticUI";
@import "FillWidth";
@import "FirebaseAuthFlow";
@import "InfoPopUp";
@import "Panel";
@import "PlanBoard";
@import "PlanBoardToolbar";
@import "PlanTable";
@import "PopoverDateTimePicker";
@import "ReactBootstrapTable";
@import "ReactDatetime";
@import "ReactSelect";
@import "ReactTable";
@import "ReactWidgetsMultiSelect";
@import "ReactWidgetsSelectList";
@import "StatesHistoryTablePagination";
@import "StickyGrid";
@import "Stub";
@import "UserProfileSidenav";
@import "TimelineRow";
@import "Timelines";

//
// Toolbar
//

@toolbarHeight: 50px;

.toolbar {
    padding: @padding-small 0 @padding-small 0;
    height: @toolbarHeight;
}

.toolbarBordered {
    border-top: 1px solid @color3;
    border-bottom: 1px solid @color3;
}

//
// Slices
//

.slice {
    padding: 0 @padding-base;
    border: 1px 0 1px 0 solid @color3;
    background: white;
}

.slice-padded {
    padding: @padding-base;
}

.AppRoot {
    height: 100%;
}

.WrapperApp {
    height: 100%;
}


.Star {
    &:hover {
        font-size: 1.25em;
    }
}
